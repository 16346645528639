<template>
    <div :style="borderColor" :class="computeClass" class="border border-transparent w-full justify-start items-center gap-2.5 inline-flex px-[0.9rem]">
        <input
          :id="id"
          :value="value"
          :type="type"
          :name="name"
          :maxlength="maxlength"
          :readonly="readonly"
          :disabled="disabled"
          :autocomplete="autocomplete"
          :placeholder="label"
          @input="updateValue($event.target.value)"
          @keyup.enter="onEnter()"
          @focus="labelFocused = true"
          class="w-full text !text-[#3C4549] text-[0.875rem] font-poppins bg-transparent p-0 transition-all ease-in-out duration-300"
          @blur="labelFocused = false, value = focusoutConcatenation(value)"
          style="border:none;"
        />
      <slot name="right_opt"></slot>
      <slot name="error_message"></slot>
      <slot name="right_icon"></slot>
    </div>
</template>

<script>
export default {
  name: 'FloatingLabelInput',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
    showIconRight: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
    maxlength: {
      type: [Number, String],
      default: 524288,
    },
    readonly: {
      type: [Boolean, String, Number],
      default: false,
    },
    disabled: {
      type: [Boolean, String, Number],
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    customClass: {
      type: String,
      default: 'h-[56px]'
    },
    errorType: {
      type: String,
      default: ''
    },
    authPageCalled: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Object
    },
    checkTelephone: {
      type: Boolean,
      default: false
    },
    checkMail: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      labelFocused: false,
      validLength: 1000
    }
  },
  computed: {
    name() {
      return this.label.toLowerCase()
    },
    borderColor() {

      if(this.errorType === 'danger') {
        return {'border': '1px solid !important', 'border-color': '#BE185D !important'}
      }
      if(this.errorType === 'warning') {
        return {'border': '1px solid !important', 'border-color': '#F4B740 !important'}
      }
      if(this.errorType === 'green') {
        return {'border': '1px solid !important', 'border-color': '#3ABA6D !important'}
      }

      if(this.authPageCalled) {
        return this.labelFocused ?  {'border': '1px solid !important', 'border-color': '#2766E4 !important'} : {'border': '1px solid !important', 'border-color': '#D2D5DF !important'}
      }

      return this.labelFocused ?  {'border': '1px solid !important' ,'border-color': '#2766E4 !important'} : ''

    },
    computeClass() {
      const errorTypeColors = {
        danger: '!bg-[#FFFCFC]',
        warning: '!bg-[#FFFEF9]',
        green: '!bg-[#FBFFFA]',
      };

      const defaultColor = this.labelFocused ? 'border-[#2766E4]' : 'border-[#D2D5DF]';
      const selectedColor = errorTypeColors[this.errorType] || defaultColor;

      return selectedColor + ' ' + this.customClass + (this.disabled ? ' select-none cursor-not-allowed' : '');
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    updateValue (val) {
      val = this.checkValidations(val)
      this.$emit('input', val)
    },
    onEnter () {
      this.checkValidations(this.value)
      const result = Object.keys(this.validations).every(k => this.validations[k] === false)
      this.$emit('onEnter', result)
    },
    checkValidations (val) {
      if (this.telCheck(val)) {
        this.validations.phoneValid = this.checkTel(val.substring(4))
      } else if (this.mailToCheck(val)) {
        this.validations.mailValid = this.checkEmail(val.substring(7))
      }else if (val && val.length > 8) {
        this.validations.url = false
        val = val.trim()
        const telCheck = this.checkTelephone && this.telCheck(val)
        const mailCheck = this.checkMail && this.mailToCheck(val)
        if (!telCheck && !mailCheck) {
          val = this.urlConcatenation(val)
          this.validations.urlLength = !this.maxLength(val, this.validLength)
          this.validations.urlValid = !this.isValidURL(val)
          this.$set(this.validations, 'url', false)
        }
      } else {
        this.validations.url = false
        this.validations.urlLength = false
        this.validations.urlValid = false
        this.validations.phoneValid = false
        this.validations.mailValid = false
      }
      return val
    }
  }
}
</script>
